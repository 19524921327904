import React from "react";
import { useLayoutEffect } from "react";
import "./CommunityGuidelines.css";
import PageBanner from "../../utils/PageBanner/PageBanner";

const CommunityGuidelines = () => {

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    })

  return (
    <div className="refund-page-container">
      <PageBanner title="NebourHood Community Guidelines" />
      <p className="title">
        <b>NEBOURHOOD COMMUNITY GUIDELINES</b>
      </p>
      <div className="container">
        {/* 1 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
            1. Professional Conduct
            <ul className="sub-list">
              <li>
                Respect and Courtesy: Treat all members with respect and courtesy. Harassment, discrimination, and abusive language will not be tolerated. Treat others how you would like to be treated and respect their boundaries.
              </li>
              <li>
                Constructive Feedback: Provide constructive and respectful feedback. Keep conversations positive, constructive, and non-judgmental. Focus on building each other up.
              </li>
            </ul>
          </li>
        </ul>
        {/* 2 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
            2. Content Sharing
            <ul className="sub-list">
              <li>
                Relevant Content: Share content relevant to interior design and architecture, including projects, tips, resources, and industry news. Avoid spamming or posting irrelevant content.
              </li>
              <li>
                Original Work: Ensure all shared content is your own or properly attributed. Plagiarism is strictly prohibited.
              </li>
              <li>
                Quality Standards: Maintain high-quality standards in all shared content, including images, videos, and written posts.
              </li>
            </ul>
          </li>
        </ul>
        {/* 3 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
              3. Engagement and Collaboration
            <ul className="sub-list">
              <li>
                Active Participation: Engage actively in discussions, events, and activities. Support and cheer for other members in their personal growth journeys. Encourage open-mindedness and a willingness to learn.
              </li>
              <li>
                Collaboration: Seek opportunities to collaborate with other professionals. Share knowledge and resources to foster a supportive community.
              </li>
              <li>
                Networking Etiquette: Network professionally and respectfully. Avoid aggressive self-promotion and respect privacy.
              </li>
            </ul>
          </li>
        </ul>
        {/* 4 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
            4. Privacy and Confidentiality
            <ul className="sub-list">
              <li>
                Confidential Information: Respect the privacy and confidentiality of other members. Do not share private messages or sensitive information without permission.
              </li>
              <li>
                Personal Information: Protect your personal information and respect the privacy of others. Refrain from contacting members via their personal WhatsApp numbers without permission.
              </li>
            </ul>
          </li>
        </ul>
        {/* 5 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
          5. Advertising and Promotions
            <ul className="sub-list">
              <li>
                Relevant Promotions: Only promote services, products, or events relevant to interior design and architecture. Do not share paid promotional events in WhatsApp groups without checking with the Admins.
              </li>
              <li>
                No Spamming: Avoid excessive or irrelevant promotions. Spamming is strictly prohibited.
              </li>
            </ul>
          </li>
        </ul>
        {/* 6 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
            6. Dispute Resolution
            <ul className="sub-list">
              <li>
                Handling Disputes: Resolve disputes amicably and professionally. Contact community moderators for assistance if needed.
              </li>
              <li>
                Reporting Issues: Report any conversations, people, or guideline violations that make you uncomfortable. We want to create a safe and welcoming space for everyone.
              </li>
            </ul>
          </li>
        </ul>
        {/* 7 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
            7. Community Contributions
            <ul className="sub-list">
              <li>
                Support New Members: Welcome and support new members by providing guidance and sharing useful resources.
              </li>
              <li>
                Contribute Positively: Contribute positively to discussions and activities. Aim to add value and support the growth of the community.
              </li>
            </ul>
          </li>
        </ul>
        {/* 8 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
            8. Compliance with Platform Policies
            <ul className="sub-list">
              <li>
                Adherence to Rules: Adhere to all platform policies and guidelines set by NebourHood.
              </li>
              <li>
                Updates and Changes: Stay informed about updates and changes to community guidelines and platform policies.
              </li>
            </ul>
          </li>
        </ul>
        {/* 9 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
            9. Prohibited Content
            <ul className="sub-list">
              <li>
                Illegal and Harmful Content: Do not post content that is explicitly obscene, sexual, pornographic, or illegal. Do not post content that encourages violence, self-harm, or suicide, or that encourages violence towards a specific group of people.
              </li>
              <li>
                Personal and Confidential Information: Do not post personal and/or confidential information belonging to another user that could potentially harm them. Do not share private messages or sensitive information without permission.
              </li>
              <li>
                Spam and Disruptive Content: Do not spam (posting the same thing more than once) or post text that disrupts the flow of chat. Avoid excessive or irrelevant promotions.
              </li>
            </ul>
          </li>
        </ul>
        {/* 10 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
          10. General Principles
            <ul className="sub-list">
              <li>
                Respect and Support: Encourage and support each other’s personal growth journeys. Embrace diversity and be willing to learn from different experiences and perspectives.
              </li>
              <li>
                Professionalism and Authenticity: Be professional and have fun, but not at the expense of others. Be your real self.
              </li>
            </ul>
          </li>
        </ul>
        {/* 11 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
            11. Consequences of Violations
            <ul className="sub-list">
              <li>
                Warnings: Members who violate community guidelines may receive warnings from moderators.
              </li>
              <li>
                Suspension or Termination: Repeated or severe violations may result in suspension or termination of membership without a refund.
              </li>
            </ul>
          </li>
        </ul>
        {/* 12 */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
          12. Celebratory Moments
            <ul className="sub-list">
              <li>
                Private Messages for Celebrations: Keep discussions about birthdays or other celebratory moments to private messages. Do not flood the group with personal greeting messages.
              </li>
            </ul>
          </li>
        </ul>
        {/* Note */}
        <ul className="list" style={{ listStyleType: 'none' }}>
          <br/>
          <li className="header">
          <span style={{ fontWeight: '700' }}>Note:</span> These guidelines apply to usernames, images, and content (of any type whatsoever) where applicable. Breaking these rules may result in a temporary or permanent ban of your NebourHood Prime Membership/account. If you have any questions regarding our community guidelines or wish to report abuse, please contact our team. Thank you.
          </li>
        </ul>
        
      </div>

     
      <div className="container">
        <p className="sub-title">
          <b>PLEASE NOTE</b>
        </p>
        <br/>
        <ul className="list">
          <li className="header">
            You would receive the details of your purchase / selection on your NebourHood registered email.
          </li>
          <li className="header">
            To view more details regarding your purchase / selection on NebourHood, please visit the Transaction Details section of your profile.
          </li>
          <li className="header">
            Verification Responsibility: NebourHood does not independently verify the details of property owners, property requirement leads, service professionals, projects, people, products, services, listings, advertisements, offers, profiles, transactions, or anything else. Users are responsible for verifying the authenticity and accuracy of the information provided by other users. All users agree to NebourHood's declaration, acknowledging their responsibility for verification.
          </li>
          <li className="header">
            Qualified Leads: On NebourHood, qualified leads are tailored to meet specific requirements, offering detailed information to assist users in making informed decisions. While these leads ensure relevance and suitability, users should understand that they are not verified and must exercise due diligence before engaging. 
          </li>
          <li className="header">
            Account Security: Users are advised to maintain the security of their NebourHood accounts by using strong, unique passwords and enabling two-factor authentication for added protection against unauthorized access.
          </li>
          <li className="header">
            Reporting Concerns: Users are encouraged to promptly report any suspicious activity, fraudulent behaviour, or violations of terms of service to NebourHood's customer support team for swift resolution and assistance.
          </li>
          <li className="header">
            NebourHood does not influence quotes or handle project execution.
          </li>
          <li className="header">
            NebourHood is a platform connecting property owners with service professionals.
          </li>
          <li className="header">
            Prices shown are indicative and average starting ranges collected from market data.
          </li>
          <li className="header">
            Actual costs may vary based on service professionals, project scope, material preferences and / or other factors.
          </li>
        </ul>
      </div>

      <div className="container">
        <p className="sub-title">
          <b>DISCLAIMER</b>
        </p>
        <br/>
        <ul className="list">
          <li className="header">
            <b>NebourHood would not be responsible</b> for the acceptance or rejection of any deals between:
            <ul className="sub-list">
              <li>
              Property owners and property owners
              </li>
              <li>
              Property owners and service professionals
              </li>
              <li>
              Service professionals and service professionals
              </li>
              <li>
              Or any others involved.
              </li>
            </ul>
          </li>
          <li className="header">
            <b>NebourHood would not be responsible</b> for any kind of terms (including service, payment or any other terms) made between:
            <ul className="sub-list">
              <li>
              Property owners and property owners
              </li>
              <li>
              Property owners and service professionals
              </li>
              <li>
              Service professionals and service professionals
              </li>
              <li>
              Or any others involved.
              </li>
            </ul>
          </li>
          <li className="header">
            Limited Liability: NebourHood disclaims responsibility for any outcomes resulting from deals or interactions between users, including property owners, service professionals, or any other parties involved. Users engage with each other at their own risk, and NebourHood does not assume liability for any disputes, losses, damages or anything else arising from such interactions.
          </li>
          <li className="header">
            Terms Negotiation: NebourHood does not intervene in the negotiation or establishment of terms, including service agreements, payments, or any other non - contractual / contractual arrangements, between users. Users are solely responsible for negotiating and adhering to mutually agreed-upon terms.
          </li>
          <li className="header">
            Third-Party Content: NebourHood may contain links or references to third-party websites, services, or content. NebourHood does not endorse or control these third-party entities and is not responsible for the accuracy, legality, or quality of their content or services.
          </li>
          <li className="header">
            Indemnification: Users agree to indemnify and hold NebourHood harmless from any claims, damages, losses, liabilities or anything else arising from their use of the platform, including but not limited to disputes with other users, breach of terms, or violation of rights.
          </li>
          <li className="header">
            Legal Compliance: Users are responsible for complying with all applicable laws, regulations, and contractual obligations when using NebourHood's services. NebourHood does not condone or facilitate any illegal activities or behaviour on / via its platform.
          </li>
          <li className="header">
            Changes to Policies: NebourHood reserves the right to modify, update, or amend its policies, terms of service, and features at any time without prior notice. Users are advised to regularly review these documents for any changes or updates that may affect their use of the platform.
          </li>
        </ul>
      </div>



    </div>
  );
};

export default CommunityGuidelines;
